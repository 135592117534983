import { inject, InjectionToken } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { auditTime, filter, map, shareReplay, startWith } from 'rxjs/operators';
import { distinctUntilChanged, Observable } from 'rxjs';

export const IS_ROUTER_IN_LOADING_STATE = new InjectionToken<Observable<boolean>>(
    'Wrapper around NavigationEvents to keep track of loading state',
    {
        providedIn: 'root',
        factory: (): Observable<boolean> => {
            const router = inject(Router);
            const isCurrentNavigation = !!router.getCurrentNavigation();
            return router.events.pipe(
                filter(
                    (event) =>
                        event instanceof NavigationStart ||
                        event instanceof NavigationEnd ||
                        event instanceof NavigationError ||
                        event instanceof NavigationCancel
                ),
                map((event) => event instanceof NavigationStart),
                startWith(isCurrentNavigation),
                auditTime(1),
                distinctUntilChanged(),
                shareReplay()
            );
        },
    }
);
