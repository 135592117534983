import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs';
import { ActivityRequired, OperationNavConfig, OperationTab } from '@data-terminal/shared-models';

const defaultOperationNav = {
    [ActivityRequired.OTHER_ACTIVITY as string]: {
        isDisabled: false,
        badge: 0,
    },
    [ActivityRequired.OPERATION as string]: {
        isDisabled: false,
        badge: 0,
    },
};

@Injectable({
    providedIn: 'root',
})
export class OperationNavigationService {
    private _config = defaultOperationNav;
    private readonly _operationTabsConfig = new BehaviorSubject<OperationNavConfig>(this._config);

    public updateOperationTab(operationTab: OperationTab): void {
        this._config[ActivityRequired.OPERATION] = operationTab;
        if (operationTab.badge > 0) {
            this._config[ActivityRequired.OTHER_ACTIVITY].badge = 0;
            this._config[ActivityRequired.OTHER_ACTIVITY].isDisabled = true;
        } else {
            this._config[ActivityRequired.OTHER_ACTIVITY].isDisabled = false;
        }
        this._operationTabsConfig.next({ ...this._config });
    }

    public updateOtherActivityTab(operationTab: OperationTab): void {
        this._config[ActivityRequired.OTHER_ACTIVITY] = operationTab;
        this._operationTabsConfig.next({ ...this._config });
    }

    public setInitialState(config: OperationNavConfig): void {
        this._operationTabsConfig.next(config);
    }

    public getOperationTabsConfig$(): Observable<OperationNavConfig> {
        return this._operationTabsConfig.pipe(distinctUntilChanged());
    }
}
